.section_container {
    background-color: #fff;
    padding: 1rem 1rem 4.5rem;
    margin: 1rem;
}

.store-search-container {
    padding: 1% 0% 1% 1%;
    margin: 0% 0% 1% 1%;
}

.non-editable-labels {
    font-weight: 600;
    font-size: 1.2rem;
    width: 16%;
}

.non-editable-text-field {
    width: 15%;
    margin: 0% 0% 2% 2%;
}

.opening-date-cal {
    height: 90%;
    width: 15%;
    margin-left: 2%;
}

.opening-date-cal > input {
    text-align: center;
    opacity: 1;
    font-weight: 530;
    color: black !important;
    line-height: 1.8;
    font-size: 1.1rem;
}

.opening-date-cal .p-inputtext:enabled:focus {
    box-shadow: none;
    border-color: #ced4da;
}

.opening-date-cal .p-inputtext:enabled:hover {
    border-color: #ced4da;
}

.opening-date-cal .p-button:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

.opening-date-cal .p-button:enabled:hover {
    background: #0000002b;
    color: #ffffff;
    border-color: inherit; 
}

.flex-div .p-disabled, .p-component:disabled {
    opacity: 1;
    font-weight: 530;
    text-align: center;
    color: black !important;
    border: 1px solid #ddd;
    line-height: 1.8;
    font-size: 1.1rem;
}

.sister-store-container {
    padding: 2% 3% 2% 1%;
    margin-left: 1%;
}

.sister-store-table {
    width: fit-content;
}

#NEW_STORE_SELECTOR {
    margin: 0% 0% 2% 2%;
    height: 3rem;
    width: 15%;
}

#NEW_SISTER_STORE_SELECTOR {
    margin: 0% 0% 2% 2%;
    height: 3rem;
    width: 15%;
}

#DC_IMPORT_BUTTON {
    padding: 0.5rem;
    font-size: 1.2rem;
    margin-top: 2%;
    position: absolute;
    left: 42%;
    width: 16%;
    font-weight: bold;
}