.select__columns {
    padding: 0;
}

.col_selection {
    width: 75%;
}

.save_col_options input {
    margin-top: 3px;
    margin-right: 3px;
}

.save_col_options label {
    margin-right: 10px;
}

.font-italic {
    font-size: 1rem !important;
}

.show_columns__label {
    font-size: 13px;
    opacity: 0.8;
}