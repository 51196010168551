.drivers_container, .adjustment_container, .hierarchy_container, .sales_data_container {
    background-color: #fff;
    padding: 1rem 1rem 4.5rem;
    margin: 1rem;
}

.radio-selection-container {
    /* display: inline-flex;
    align-items: baseline; */
    display: flex;
}

.radio-selector {
    margin-top: 2rem;
}

.date-range {
    height: 5vh;
    margin: 0% 2% 0% 1%;
}

.p-button {
    color: #ced4da;
    border: 1px solid hsl(0,0%,80%);
    background-color: hsl(0,0%,100%);
}

.calendar-tab {
    margin: 25px 10px 0 10px;
    border: 1px solid hsl(0,0%,80%);
    display: inline-flex;
    border-radius: 6px;
}

.forecast-multiplier-opacity {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 10001;
}

.graph-types-tab {
    /* display: flex; */
    /* justify-content: flex-end; */
    float: right;
    margin: -3% 0% 0% 0%;
    /* margin: 25px 10px 0 10px; */
    border: 1px solid hsl(0,0%,80%);
}

.ada__tabs .tab-content {
    max-width: 90%;
}

.forecast-button {
    float: right;
    transform: rotate(90deg);
    margin-top: -18%;
}

.nav-tabs {
    margin-left: 15px !important;
}

.ada__tabs a.nav-item.nav-link {
    border: none;
    font-size: 1.2rem;
    color: var(--color-neutral-a-s-b) !important;
}

.ada__tabs a.nav-item.nav-link.active {
    border: 1px solid #0d89ec;
    font-size: 1.2rem;
    color: #0d89ec !important;
    font-weight: 800 !important;
    border-bottom: none;
    border-bottom: 1px solid var(--color-primary) !important;
}

a.p-accordion-header-link {
    height: 6vh;
    font-size: 1.4rem;
}

.p-accordion .p-accordion-header .p-accordion-header-link {
    border: none;
    background-color: inherit;
}

.p-accordion .p-accordion-content {
    border: none;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
    border: none;
    background-color: inherit;
}

.adjustment_container, .hierarchy_container {
    width: 90%;
    max-width: 90%;
}

.line-chart__heading {
    padding: 1rem;
    font-weight: bold;
}

.disable-content {
    pointer-events: none;
}

.data_input input.p-inputtext{
    max-width: 100%;
    border-radius: 0;
    text-align: center;
    color: #212529;
    border: 1px solid #a6d5fa63;
    background-color: inherit;
}

.data_input input.p-inputtext:enabled:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
}

.data_disabled input.p-inputtext{
    max-width: 100%;
    border-radius: 0;
    text-align: center;
    color: #212529;
    border: 1px solid #a6d5fa63;
    background-color: inherit;
}

.data_disabled input.p-inputtext:enabled:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
}

.data_input input.p-component:disabled, input.p-disabled {
    opacity: inherit;
}

.data_disabled input.p-component:disabled, input.p-disabled {
    opacity: inherit;
}

.multiplier_input input.p-inputtext {
    max-width: 100%;
    text-align: left;
    color: #212529;
    border: none;
    background-color: inherit;
    font-size: 1rem;
}

.multiplier_input input.p-inputtext:enabled:focus {
    box-shadow: none;
    -webkit-box-shadow: none;
    border-color: none;
    outline: none;
    outline-offset: none;
}

.warning-dialog-header {
    padding: 2rem 1rem;
    border-bottom: 1px solid #dee2e6;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
}

.warning-dialog-middle {
    margin-top: 5%;
}

.warning-msg {
    color: #212529;
    font-size: 1.5rem;
    text-align: center;
}

.warning-dialog-footer{
    display: flex;
    justify-content: flex-end;
    border-top: 1px solid #dee2e6;
    border-bottom-right-radius: calc(.2rem - 1px);
    border-bottom-left-radius: calc(.2rem - 1px);
    padding: 8px;
}

.empty {
    color: rgb(0 0 0 / 62%);
    font-size: 2rem;
    margin: 20px auto;
    padding: 10px;
    width: max-content;
}

.empty p {
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
    color: rgb(0 0 0 / 62%);
}
