.add-pro-btn {
    width: fit-content;
    margin-left: auto !important;
    margin-bottom: 1.5rem;
}

.flex-box-column {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}