/* ---------------------------------------------------
    SIDEBAR STYLE
----------------------------------------------------- */
// 1rem = 10px
#sidebar {
  --selected-color: #5a7c90;
  --width: 5.8rem;
  --font-color: #fff;
  --active-width: 270px;
  --hover-color: #547689;
}

#sidebar {
  width: var(--width);
  color: var(--font-color);
  transition: width 500ms ease;
  height: 100%;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  background-color: var(--color-primary);
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;

  &:hover {
    overflow: hidden;
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
  }

  ul {
    li {
      a {
        text-align: center;
        display: block;
        height: 100%;
        font-size: 1.1rem;
        padding: 5px;

        &:hover,
        &.active {
          background: var(--hover-color) 0% 0% no-repeat padding-box;
        }

        i {
          margin: 0 1rem 0.4rem 1rem;
          font-size: 1.9rem;
        }
      }

      &.active > a {
        background: var(--hover-color) 0% 0% no-repeat padding-box;
      }

      &.link {
        height: 5.5rem;
      }

      &.hide {
        display: none;
      }

      .link-label {
        height: 100%;
        line-height: 50px;
        vertical-align: middle;
        font-size: 1.4rem;
        text-align: left;
      }
    }
  }

  .hidetext {
    opacity: 0;
    font-size: 1.3rem;
    vertical-align: middle;
  }

  .fontstyling {
    color: var(--font-color);
  }

  .sidebar-toggle-arrow {
    position: relative;
    margin: 9px 0 0.3rem 0;
    height: 2rem;
  }

  .sidebar-toggle-arrow-icon {
    height: 1.5rem;
    width: 1rem;
    font-size: 2.4rem;
    cursor: pointer;
    position: absolute;
    top: 0%;
    transform: translate(-50%, 50%);
  }

  .sidebar-toggle-arrow-right-icon {
    left: 50%;
  }

  .sidebar-toggle-arrow-left-icon {
    right: 5%;
  }

  .sidebar-header {
    padding: 1rem;
  }

  .list-unstyled {
    list-style: none;
  }

  // Active styles
  &.active {
    width: var(--active-width);

    ul {
      li {
        a {
          text-align: left;
          padding: 5px 10px 5px 5px;
          font-size: 1.3rem;
        }
      }
    }

    .hidetext {
      transition: opacity 500ms ease-in-out 250ms;
      opacity: 1;
    }
  }
}

a[aria-expanded="false"] {
  background: #547689 0% 0% no-repeat padding-box;
}

.icon {
  width: 24px;
  margin: 0 8px 0px 10px;
}

.disabledCursor {
  cursor: not-allowed;
}

/* ---------------------------------------------------
  MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    min-width: 80px;
    max-width: 80px;
    text-align: center;
    margin-left: -80px !important;

    &.active {
      margin-left: 0 !important;
    }

    ul {
      li {
        a {
          padding: 1rem 1rem;

          span {
            font-size: 1.3rem;
          }

          i {
            margin-right: 0;
            display: block;
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}

.fa-phone {
  color: #aaaaaa !important;
  margin: 0 5px 0 15px !important;
}