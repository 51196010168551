.table-set {
    display: block;
    font-size: 1.3rem;
    overflow-x: auto;
    border-collapse: collapse
}

.th-set {
    width: 150px;
    font-weight: bold;
    color: white !important;
    background-color: #44677b;
    text-align: center;
    height: 6vh;
}

.tr-set {
    border: 1px solid #ddd;
}

.td-set {
    align-items: center;
    min-height: 38px;
    line-height: 2.5;
    text-align: center;
    background-color: #fff;
    color: black !important;
    font-weight: 400;
    width: 100px;
    min-width: 100px;
    max-width: 100px;
}

.span-data {
    display: inline-block;
    width: 50%;
    background-color: #a6d5fa63;
    margin: 5% 0% 5% 0%;
    color: #0000009c;
}

.sticky-col {
    position: sticky;
    min-width: 100px;
    width:100px;
    left: 0;
    text-align: left;
}

.sticky-col2 {
    position: sticky;
    min-width: 100px;
    width:100px;
    left: 100px;
    text-align: center;
}

.edit-span {
    display: inline-block;
    width: 50%;
    background-color: #a6d5fa00;
    margin: 5% 0% 5% 0%;
    color: #0000009c;
    border: 1px solid #a6d5fa63;
}
  
.hierarchy-table {
    max-height: 80vh;
    max-width: 95%;
    overflow-y: auto;
    overflow-x: auto;
}

.sticky-header {
    /* position: sticky;
    top: 0; */
    position: sticky;
    min-width: 100px;
    width:100px;
    left: 0;
    text-align: left;
}

.hierarchy-table th.sticky-col, .hierarchy-table td.sticky-col {
    text-align: center;
}