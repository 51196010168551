.section {
  background-color: #fff;
  padding: 1rem;
  margin: 1rem;
}

.section > div {
  margin: 1rem 0 0 0;
}

.input-label {
  color: #ababab;
  font-size: 1.35rem;
  display: block;
}

.react-select-container {
  height: 38px;
  cursor: pointer;
}
.react-select__control {
  min-height: 100% !important;
  height: 100%;
}
.react-select__control:hover {
  cursor: pointer;
}
.react-select__control--is-focused {
  border: 1px solid #44677b !important;
  box-shadow: 0 0 2px 0 #44677b !important;
}
.react-select__value-container {
  min-height: 100% !important;
  height: 100%;
}
.react-select__indicators {
  height: 100%;
  border: none;
}
.react-select__indicator-separator {
  visibility: hidden;
}
.react-select__input {
  font-size: 13px;
}
.react-select__menu {
  font-size: 12px;
}
.react-select__option {
  text-align: left;
}

.ag-theme-balham .ag-header {
  background-color: #ecf0f2 !important;
  color: #44677b;
  text-align: center !important;
}
.ag-theme-balham .ag-ltr .ag-cell {
  color: #767b83 !important;
  text-align: center !important;
}

.ag-header-cell {
  border: none !important;
}

.ag-header-cell-label {
  justify-content: center;
}

.css-1v99tuv {
  color: #4e4c4c !important;
  font-size: 1.14rem;
  padding-bottom: 4px;
}

.css-48ayfv {
  height: 32px !important;
  /* border-radius: 6px !important; */
  border: 1px solid #c7c7c7 !important;
  padding: 3px 10px 5px 10px !important;
  width: 400px !important;
}

.css-48ayfv + div {
  z-index: 5 !important;
}

.css-1jllj6i-control {
  display: block !important;
}

.css-1r4vtzz {
  width: 100% !important;
}

/*****************************
multiselect styles start 
******************************/

.css-1r4vtzz {
  box-shadow: 0 0px 0px 1px #ccc !important;
}

.css-tlfecz-indicatorContainer{
  color: #4e4c4c !important;
}

.css-1wy0on6 {
  position: absolute;
  top: 8px;
  right: 0;
}

.react-select__indicators.css-1wy0on6{
  position: relative;
  top: 0
}

.css-151xaom-placeholder {
  line-height: 30px !important;
  top: 50% !important;
}

.filterMultiSelectCell {
  width: 240px;  
  padding: 2px 0;
}

.css-1g6gooi {
  margin-top: 0 !important;
  line-height: 20px;
}
.chart-attribute-dropdown .css-1pcexqc-container {
  height: 25px;
}
.css-1jllj6i-control,
.css-6y1x9t-control {
  min-height: 30px !important;
  /* min-width: 100% !important; */
  width: 100% !important;
  margin: 0 !important;
}
.css-1g6gooi input {
  line-height: 28px;
}
.css-1vr111p-option,
.css-1qprcsu-option {
  /* min-width: 100% !important;
  text-align: left;
  padding-top: 0 !important;
  padding-bottom: 0 !important; */
  font-size: 13px !important;
  line-height: 30px;
  font-weight: 300 !important;
  text-align: left;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07) !important;
  background-color: white !important;
}
.css-11unzgr {
  border: 1px solid #ccc;
  border-radius: 6px;
  max-height: 200px !important;
  width: 100%;
  background-color: white;
  box-shadow: 0 1px 15px 1px rgba(62, 57, 107, 0.07) !important;
}
.css-1uccc91-singleValue {
  font-size: 12px;
  line-height: 23px !important;
}
.react-select__placeholder {
  top: 50% !important;
  font-size: 12px;
  line-height: 23px !important;
}
.css-1wa3eu0-placeholder {
  color: hsl(0, 0%, 20%) !important;
  margin-left: 2px;
  margin-right: 2px;
  max-width: calc(100% - 8px);
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  white-space: nowrap;
  top: 50% !important;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  box-sizing: border-box;
  margin: 0 !important;
}
.css-1hwfws3 {
  font-size: 12px;
  width: 100%;
  align-items: center !important;
  display: flex !important;
  padding: 2px 8px !important;
  position: relative !important;
  overflow: none !important;
}

.css-bg1rzq-control {
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: hsl(0, 0%, 100%);
  border-color: hsl(0, 0%, 80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  min-height: 38px;
  outline: 0 !important;
  position: relative;
  -webkit-transition: all 100ms;
  transition: all 100ms;
  box-sizing: border-box;
}
.react-select__control--is-focused .react-select__single-value {
  top: 60% !important;
}

/*****************************
Date range picker styles 
******************************/
.DateRangePickerInput.DateRangePickerInput_1.DateRangePickerInput__withBorder.DateRangePickerInput__withBorder_2 {
  width: 100% !important;
}
.DateRangePicker.DateRangePicker_1 {
  width: 100% !important;
}
.DateInput_input {
  font-size: 0.9rem !important;
}
.SingleDatePickerInput__withBorder {
  width: 120% !important;
}
.SingleDatePicker .DateInput_input {
  width: 120% !important;
}
.Select-control {
  background-color: #fff;
  border-color: #d9d9d9 #ccc #b3b3b3;
  border-radius: 4px;
  border: 1px solid #ccc;
  color: #333;
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 36px;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.Select-control:hover {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
}
.Select-control .Select-input:focus {
  outline: none;
  background: #fff;
}
.Select-placeholder,
.Select--single > .Select-control .Select-value {
  bottom: 0;
  color: #aaa;
  left: 0;
  line-height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.Select-input {
  height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: middle;
}
.Select-input > input {
  width: 100%;
  background: none transparent;
  border: 0 none;
  box-shadow: none;
  cursor: default;
  display: inline-block;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  outline: none;
  line-height: 17px;
  /* For IE 8 compatibility */
  padding: 8px 0 12px;
  /* For IE 8 compatibility */
  -webkit-appearance: none;
}
.is-focused .Select-input > input {
  cursor: text;
}
.has-value.is-pseudo-focused .Select-input {
  opacity: 0;
}
.Select-control:not(.is-searchable) > .Select-input {
  outline: none;
}
.Select-clear-zone {
  -webkit-animation: Select-animation-fadeIn 200ms;
  -o-animation: Select-animation-fadeIn 200ms;
  animation: Select-animation-fadeIn 200ms;
  color: #999;
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 17px;
}
.Select-clear-zone:hover {
  color: #d0021b;
}
.Select-clear {
  display: inline-block;
  font-size: 18px;
  line-height: 1;
}
.Select--multi .Select-clear-zone {
  width: 17px;
}
.Select-arrow-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 25px;
  padding-right: 5px;
}
.Select--rtl .Select-arrow-zone {
  padding-right: 0;
  padding-left: 5px;
}
.Select-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative;
}
.Select-control > *:last-child {
  padding-right: 5px;
}
.Select--multi .Select-multi-value-wrapper {
  display: inline-block;
}
.Select .Select-aria-only {
  position: absolute;
  display: inline-block;
  height: 1px;
  width: 1px;
  margin: -1px;
  clip: rect(0, 0, 0, 0);
  overflow: hidden;
  float: left;
}
@-webkit-keyframes Select-animation-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes Select-animation-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.Select-menu-outer {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top-color: #e6e6e6;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
  box-sizing: border-box;
  /* margin-top: -1px; */
  max-height: 200px;
  position: absolute;
  max-width: 100%;
  min-width: 90%;
  z-index: 999;
  -webkit-overflow-scrolling: touch;
}
.Select-menu {
  max-height: 198px;
  overflow-y: auto;
}
.Select-option {
  box-sizing: border-box;
  background-color: #fff;
  color: #666666;
  cursor: pointer;
  display: block;
  padding: 8px 10px;
}
.Select-option:last-child {
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.Select-option.is-selected {
  background-color: #f5faff;
  /* Fallback color for IE 8 */
  background-color: rgba(0, 126, 255, 0.04);
  color: #333;
}
.Select-option.is-focused {
  background-color: #ebf5ff;
  /* Fallback color for IE 8 */
  background-color: rgba(0, 126, 255, 0.08);
  color: #333;
}
.Select-option.is-disabled {
  color: #cccccc;
  cursor: default;
}
.Select-noresults {
  box-sizing: border-box;
  color: #999999;
  cursor: default;
  display: block;
  padding: 8px 10px;
}
.Select--multi .Select-input {
  vertical-align: middle;
  margin-left: 10px;
  padding: 0;
}
.Select--multi.Select--rtl .Select-input {
  margin-left: 0;
  margin-right: 10px;
}
.Select--multi.has-value .Select-input {
  margin-left: 5px;
}
.Select--multi .Select-value {
  background-color: #ebf5ff;
  /* Fallback color for IE 8 */
  background-color: rgba(0, 126, 255, 0.08);
  border-radius: 2px;
  border: 1px solid #c2e0ff;
  /* Fallback color for IE 8 */
  border: 1px solid rgba(0, 126, 255, 0.24);
  color: #44677b;
  display: inline-block;
  font-size: 0.9em;
  line-height: 1.4;
  margin-left: 5px;
  margin-top: 5px;
  vertical-align: top;
}
.Select--multi .Select-value-icon,
.Select--multi .Select-value-label {
  display: inline-block;
  vertical-align: middle;
}
.Select--multi .Select-value-label {
  border-bottom-right-radius: 2px;
  border-top-right-radius: 2px;
  cursor: default;
  padding: 2px 5px;
}
.Select--multi a.Select-value-label {
  color: #44677b;
  cursor: pointer;
  text-decoration: none;
}
.Select--multi a.Select-value-label:hover {
  text-decoration: underline;
}
.Select--multi .Select-value-icon {
  cursor: pointer;
  border-bottom-left-radius: 2px;
  border-top-left-radius: 2px;
  border-right: 1px solid #c2e0ff;
  /* Fallback color for IE 8 */
  border-right: 1px solid rgba(0, 126, 255, 0.24);
  padding: 1px 5px 3px;
}
.Select--multi .Select-value-icon:hover,
.Select--multi .Select-value-icon:focus {
  background-color: #d8eafd;
  /* Fallback color for IE 8 */
  background-color: rgba(0, 113, 230, 0.08);
  color: #0071e6;
}
.Select--multi .Select-value-icon:active {
  background-color: #c2e0ff;
  /* Fallback color for IE 8 */
  background-color: rgba(0, 126, 255, 0.24);
}
.Select--multi.Select--rtl .Select-value {
  margin-left: 0;
  margin-right: 5px;
}
.Select--multi.Select--rtl .Select-value-icon {
  border-right: none;
  border-left: 1px solid #c2e0ff;
  /* Fallback color for IE 8 */
  border-left: 1px solid rgba(0, 126, 255, 0.24);
}
.Select--multi.is-disabled .Select-value {
  background-color: #fcfcfc;
  border: 1px solid #e3e3e3;
  color: #333;
}
.Select--multi.is-disabled .Select-value-icon {
  cursor: not-allowed;
  border-right: 1px solid #e3e3e3;
}
.Select--multi.is-disabled .Select-value-icon:hover,
.Select--multi.is-disabled .Select-value-icon:focus,
.Select--multi.is-disabled .Select-value-icon:active {
  background-color: #fcfcfc;
}
@keyframes Select-animation-spin {
  to {
    transform: rotate(1turn);
  }
}
@-webkit-keyframes Select-animation-spin {
  to {
    -webkit-transform: rotate(1turn);
  }
}
.close-icon-dropdown {
  position: absolute;
  z-index: 99;
  top: 59%;
  border-radius: 9px;
  padding: 1px 2px;
  font-size: 11px;
  left: 68%;
  cursor: pointer;
}

.black-color {
  color: black;
}
